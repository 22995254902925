import React, { useState, useEffect } from "react";
import Navbar from "./Navbar";
import "./gradientGenerator.css";

const ColorCalculator = () => {
  const [hexCode, setHexCode] = useState("");
  const [rgbCode, setRgbCode] = useState("");
  const [backgroundColor, setBackgroundColor] = useState(
    "#f0ab23" || backgroundColor
  ); // Default background color

  useEffect(() => {
    document.body.style.backgroundColor = backgroundColor;
    return () => {
      document.body.style.backgroundColor = ""; // Reset background color when the component unmounts
    };
  }, [backgroundColor]);

  const updateRgbCode = (hex) => {
    try {
      hex = hex.replace(/^#/, "");

      if (hex.length === 3) {
        hex = hex
          .split("")
          .map((char) => char + char)
          .join("");
      }

      const bigint = parseInt(hex, 16);
      const r = (bigint >> 16) & 255;
      const g = (bigint >> 8) & 255;
      const b = bigint & 255;

      const newRgbCode = `rgb(${r}, ${g}, ${b})`;
      setRgbCode(newRgbCode);
      setBackgroundColor(newRgbCode);
    } catch (error) {
      console.error("Invalid color code:", error.message);
      setRgbCode("");
      setBackgroundColor("");
    }
  };

  const updateHexCode = (rgb) => {
    try {
      const match = rgb.match(/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/);

      if (!match) {
        throw new Error("Invalid RGB color code");
      }

      const hex = (x) => {
        return ("0" + parseInt(x).toString(16)).slice(-2);
      };

      const newHexCode = `#${hex(match[1])}${hex(match[2])}${hex(match[3])}`;
      setHexCode(newHexCode);
      setBackgroundColor(rgb);
    } catch (error) {
      console.error("Invalid color code:", error.message);
      setHexCode("");
      setBackgroundColor("");
    }
  };

  return (
    <>
      <div
        className="container"
        style={{
          background: backgroundColor,
          minHeight: "100vh",
        }}
      >
        <Navbar style={{ background: backgroundColor }} />

        <label>
          <input
            type="text"
            placeholder="Hex Code"
            value={hexCode}
            onChange={(e) => {
              setHexCode(e.target.value);
              updateRgbCode(e.target.value);
            }}
          />
        </label>

        <label>
          <input
            type="text"
            placeholder="RGB Code"
            value={rgbCode}
            onChange={(e) => {
              setRgbCode(e.target.value);
              updateHexCode(e.target.value);
            }}
          />
        </label>
      </div>
    </>
  );
};

export default ColorCalculator;
