import { Route, Routes } from "react-router-dom";
import ColorPicker from "./components/ColorPicker";
import GradientPicker from "./components/GradientPicker";
import Navbar from "./components/Navbar";
import GradientGenerator from "./components/GradientGenerator";
import ColorCalculator from "./components/ColorCalculator";
import RequestFail from "./components/RequestFail";
import ReactGA from "react-ga4";
import TagManager from "react-gtm-module";
// import { Helmet } from "react-helmet";

function App() {
  // const TRACKING_ID = process.env.TRACKING_ID;
  // ReactGA.initialize(TRACKING_ID);
  // ReactGA.pageview(document.location.pathname);

  TagManager.initialize({
    gtmId: process.env.GTM_ID,
  });

  return (
    <div className="App">
      {/* <Navbar /> Navbar outside Routes to be shown on every route */}
      {/* <Helmet>
        <script
          async
          src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-4213085549503163"
          crossorigin="anonymous"
        ></script>
        <script>
          {`(adsbygoogle = window.adsbygoogle || []).push({
            google_ad_client: 'ca-pub-4213085549503163',
            enable_page_level_ads: true
          });`}
        </script>
      </Helmet> */}
      <Routes>
        <Route path="/" element={<ColorPicker />} />
        <Route path="/color-picker" element={<ColorPicker />} />
        <Route path="/gradient-picker" element={<GradientPicker />} />
        <Route path="/gradient-generator" element={<GradientGenerator />} />
        <Route path="/hex-to-rgb" element={<ColorCalculator />} />
        <Route path="*" element={<RequestFail />} />
      </Routes>
    </div>
  );
}

export default App;
