import React, { useState, useEffect } from "react";
import Navbar from "./Navbar";
import "./colorPicker.css";

const GradientPicker = () => {
  const [randomGradient, setRandomGradient] = useState("");
  const [firstTimeMessage, setFirstTimeMessage] = useState(true);

  const generateRandomGradient = () => {
    const randomColor1 = `#${Math.floor(Math.random() * 16777215).toString(
      16
    )}`;
    const randomColor2 = `#${Math.floor(Math.random() * 16777215).toString(
      16
    )}`;
    const gradient = `linear-gradient(to right, ${randomColor1}, ${randomColor2})`;
    setRandomGradient(gradient);
    setFirstTimeMessage(false);
  };

  const copyToClipboard = () => {
    const tempTextArea = document.createElement("textarea");
    tempTextArea.value = randomGradient;
    document.body.appendChild(tempTextArea);
    tempTextArea.select();
    document.execCommand("copy");
    document.body.removeChild(tempTextArea);
    // alert("Gradient code copied to clipboard!");
  };

  const calculateTextColor = (hexColor) => {
    const r = parseInt(hexColor.slice(1, 3), 16);
    const g = parseInt(hexColor.slice(3, 5), 16);
    const b = parseInt(hexColor.slice(5, 7), 16);
    const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;
    return luminance > 0.5 ? "#000" : "#fff";
  };

  const textColor = calculateTextColor(randomGradient);

  const isTouchDevice = "ontouchstart" in window || navigator.msMaxTouchPoints;

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.code === "Space") {
        generateRandomGradient();
      }
    };

    const handleTap = () => {
      generateRandomGradient();
    };

    window.addEventListener("keydown", handleKeyDown);

    if (isTouchDevice) {
      window.addEventListener("touchstart", handleTap);
    }

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      if (isTouchDevice) {
        window.removeEventListener("touchstart", handleTap);
      }
    };
  }, [isTouchDevice]);

  const defaultBackgroundColor = "#f23a23"; // Light gray

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        background: randomGradient || defaultBackgroundColor,
        minHeight: "100vh",
        padding: "20px",
      }}
    >
      <Navbar
        style={{ background: randomGradient || defaultBackgroundColor }}
      />
      {firstTimeMessage && (
        <div
          style={{
            marginBottom: "20px",
            color: "#fff",
            fontFamily: "Ubuntu",
            fontSize: "3rem",
          }}
        >
          {isTouchDevice ? "Tap On Screen" : "Press the Spacebar"}
        </div>
      )}

      {randomGradient && (
        <div
          className="codeAndButton"
          style={{
            marginTop: "20px",
            color: textColor,
            display: "flex",
            gap: "2rem",
            alignItems: "center",
          }}
        >
          <p>{randomGradient}</p>
          <button onClick={copyToClipboard} style={{ marginLeft: "10px" }}>
            Copy
          </button>
        </div>
      )}
    </div>
  );
};

export default GradientPicker;
