import React, { useState, useEffect } from "react";
import Navbar from "./Navbar";
import copyImg from "../assets/copy.png";
import "./colorPicker.css";

const ColorPicker = () => {
  const [randomColor, setRandomColor] = useState("");
  const [firstTimeMessage, setFirstTimeMessage] = useState(true);

  const generateRandomColor = () => {
    const randomColorCode = `#${Math.floor(Math.random() * 16777215).toString(
      16
    )}`;
    setRandomColor(randomColorCode);
    setFirstTimeMessage(false);
  };

  const copyToClipboard = () => {
    const tempTextArea = document.createElement("textarea");
    tempTextArea.value = randomColor;
    document.body.appendChild(tempTextArea);
    tempTextArea.select();
    document.execCommand("copy");
    document.body.removeChild(tempTextArea);
    // alert("Color code copied to clipboard!");
  };

  const calculateTextColor = (hexColor) => {
    const r = parseInt(hexColor.slice(1, 3), 16);
    const g = parseInt(hexColor.slice(3, 5), 16);
    const b = parseInt(hexColor.slice(5, 7), 16);
    const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;
    return luminance > 0.5 ? "#000" : "#fff";
  };

  const textColor = calculateTextColor(randomColor);

  const isTouchDevice = "ontouchstart" in window || navigator.msMaxTouchPoints;

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.code === "Space") {
        generateRandomColor();
      }
    };

    const handleTap = () => {
      generateRandomColor();
    };

    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("touchstart", handleTap);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("touchstart", handleTap);
    };
  }, []); // Empty dependency array means this effect runs once on mount

  const defaultBackgroundColor = "#f0ab23"; // Light gray

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: randomColor || defaultBackgroundColor,
        minHeight: "100vh",
        padding: "20px",
      }}
    >
      <Navbar
        style={{
          background: randomColor || defaultBackgroundColor,
          color: textColor,
        }}
      />
      {firstTimeMessage && (
        <div
          style={{
            marginBottom: "20px",
            color: textColor,
            fontFamily: "Ubuntu",
            fontSize: "3rem",
          }}
        >
          {isTouchDevice ? "Tap On Screen" : "Press the Spacebar"}
        </div>
      )}

      {randomColor && (
        <div
          style={{
            marginTop: "20px",
            color: textColor,
            display: "flex",
            gap: "2rem",

            alignItems: "center",
          }}
        >
          <p>{randomColor}</p>
          <button onClick={copyToClipboard} style={{ marginLeft: "10px" }}>
            Copy
          </button>
        </div>
      )}
    </div>
  );
};

export default ColorPicker;
