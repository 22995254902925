import React from "react";
import { useNavigate } from "react-router-dom";
import avatarImg from "../assets/avatar.png";

const RequestFail = () => {
  const navigate = useNavigate();

  const copyToClipboard = () => {
    // Add any necessary logic for copying to clipboard if needed

    // Navigate to the home component
    navigate("/", { replace: true });
  };

  return (
    <div
      className="avatar"
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        background: "#f0ab23",
        height: "100vh",
        gap: "2rem",
      }}
    >
      <p style={{ fontSize: "2rem", color: "#fff" }}>
        404:Code shades out of sight.
        <br /> Go back or dive into ChromaPick's vibrant spectrum.
      </p>
      {/* <p
        style={{
          fontSize: "10rem",
          letterSpacing: "10rem",
          alignItems: "center",
          fontWeight: "bolder",
          color: "#fff",
        }}
      >
        404
      </p> */}
      <img style={{ width: "15rem" }} src={avatarImg} alt="avatar" />
      <button onClick={copyToClipboard} style={{ marginBottom: "10px" }}>
        Back
      </button>
    </div>
  );
};

export default RequestFail;
