import React, { useState, useEffect } from "react";
import "./gradientGenerator.css";
import Navbar from "./Navbar";

const GradientGenerator = () => {
  const [color1, setColor1] = useState("#ff0066");
  const [color2, setColor2] = useState("#3700ff");
  const [gradientDirection, setGradientDirection] = useState("to right");
  const [gradient, setGradient] = useState("");

  useEffect(() => {
    generateGradient();
  }, [color1, color2, gradientDirection]); // Trigger generation on color or direction change

  const generateGradient = () => {
    const linearGradient = `linear-gradient(${gradientDirection}, ${color1}, ${color2})`;
    setGradient(linearGradient);
    setBackgroundColor(linearGradient);
  };

  const setBackgroundColor = (color) => {
    document.body.style.backgroundColor = color;
  };

  return (
    <>
      <div className="container" style={{ background: gradient }}>
        {" "}
        <Navbar />
        <label>
          <input
            type="color"
            value={color2}
            onChange={(e) => setColor2(e.target.value)}
          />
        </label>
        <label>
          <select
            value={gradientDirection}
            onChange={(e) => setGradientDirection(e.target.value)}
          >
            <option value="to right">Right</option>
            <option value="to left">Left</option>
            <option value="to top">Top</option>
            <option value="to bottom">Bottom</option>
            <option value="to top right">Top Right</option>
            <option value="to top left">Top Left</option>
            <option value="to bottom right">Bottom Right</option>
            <option value="to bottom left">Bottom Left</option>
            <option value="to right top">Right Top</option>
            <option value="to right bottom">Right Bottom</option>
            <option value="to left top">Left Top</option>
            <option value="to left bottom">Left Bottom</option>
          </select>
        </label>
        <label>
          <input
            type="color"
            value={color1}
            onChange={(e) => setColor1(e.target.value)}
          />
        </label>
        <p style={{ fontSize: "2rem" }}>{gradient}</p>
      </div>
    </>
  );
};

export default GradientGenerator;
